import Config from '../config.json'

const validLetters: string = Config.validLetters;
const targetText: string = Config.targetText;

const isTextOk = (text: string): boolean => {
    return text.length === 0 || targetText.startsWith(text);
}

const isStart = (text: string): boolean => {
    return text.length === 1 && targetText.startsWith(text);
}

const isTextMatching = (text: string): boolean => {
    return targetText === text;
}

export {isTextOk, isStart, isTextMatching, validLetters}