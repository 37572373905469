import './TimeDisplay.css'

type TimeDisplayProps = {
    timeMs: number;
};

function TimeDisplay({ timeMs }: TimeDisplayProps) {

    const formatTime = (timeMillis: number): string => `${Math.floor(timeMillis / 1000)}.${("000" + (timeMillis % 1000)).slice(-3)}`;

    return (
        <span className="Race-font">{`${formatTime(timeMs)}`}</span>
    );
}

export default TimeDisplay;
