import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './TextType.css';
import { isStart, isTextMatching, isTextOk, validLetters } from './gameLogic';
import matchSfx from '../resources/match.mp3';
import wrongSfx from '../resources/wrong.mp3';

export enum StopwatchStatus {
  OFF, ON
}

export enum GameStatus {
  IDLE, ONGOING, LOSE, WIN
}

type TextTypeProps = {
  stopwatchStatus: StopwatchStatus;
  setStopwatchStatus: Function;
  setGameStatus: Function;
  isSfx: boolean;
}


function TextType({ stopwatchStatus, setStopwatchStatus, isSfx: isMusic, setGameStatus }: TextTypeProps) {
  const [userText, setUserText] = useState("");
  const newLettersTyped: string[] = useMemo(() => [], []);
  const wrongSfxAudio = useMemo(() => new Audio(wrongSfx), []);
  const matchSfxAudio = useMemo(() => new Audio(matchSfx), []);
  matchSfxAudio.volume = 0.5;

  const handleUserKeyPress = useCallback((event: KeyboardEvent) => {
    const { key } = event;
    if (validLetters.includes(key)) {
      newLettersTyped.push(key);
      setUserText(prevUserText => `${prevUserText}${key}`);
    }
  }, [setUserText, newLettersTyped]);


  useEffect(function listenToKeyPresses() {
    window.addEventListener("keydown", handleUserKeyPress);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [handleUserKeyPress]);


  useEffect(function handleChangeInText() {
    if (stopwatchStatus === StopwatchStatus.OFF) {

      //? Start
      if (isStart(userText)) {
        setStopwatchStatus(StopwatchStatus.ON)
        setGameStatus(GameStatus.ONGOING)
      }

      //? Wrong but not started
      if (!isTextOk(userText)) {
        setUserText("")
        newLettersTyped.splice(0, newLettersTyped.length);
        setGameStatus(GameStatus.IDLE)
      }
    }


    else {

      //? Wrong
      if (!isTextOk(userText)) {
        setStopwatchStatus(StopwatchStatus.OFF)
        if (isMusic) {
          wrongSfxAudio.play();
        }
        setGameStatus(GameStatus.LOSE)
      }

      //? Win
      if (isTextMatching(userText)) {
        if (isMusic) {
          matchSfxAudio.play();
        }
        setStopwatchStatus(StopwatchStatus.OFF)
        setGameStatus(GameStatus.WIN)
      }
    }
  }, [newLettersTyped, isMusic, matchSfxAudio, setGameStatus, setStopwatchStatus, stopwatchStatus, userText, wrongSfxAudio]);

  return (
    <>
      <span className={"Text-container"}>
        {!userText.length && <span className="Text-idle">תתחילו לכתוב!</span>}
        {newLettersTyped.map((letter, index) => <span className="Text-insert" key={index}>{letter}</span>)}
      </span>
    </>
  );
}

export default TextType;
