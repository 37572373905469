import musicVer1 from '../resources/Southern Rock - Country Blues Jam Track in E.mp3';
import musicVer2 from '../resources/Fast Blues Backing Track in B - Blues Jam Tracks.mp3';
import { useEffect, useMemo } from 'react';

type MusicProps = {
    isMusic: boolean;
};

function PlayMusic({ isMusic }: MusicProps) {
    const music = useMemo(() => new Audio(Math.random() > 0.5 ? musicVer1 : musicVer2), []);

    useEffect(function musicInit() {
        music.loop = true;
        if (isMusic) {
            music.play();
            music.volume = 0.05;
        } else {
            music.volume = 0;
        }
    }, [isMusic, music])

    return (
        <></>
    );
}

export default PlayMusic;
