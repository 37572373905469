import { useRef, useState, useEffect } from "react";
import { GameStatus, StopwatchStatus } from "../TextType/TextType";
import './Stopwatch.css'
import TimeDisplay from "../TimeDisplay/TimeDisplay";


type StopwatchProps = {
    stopwatchStatus: StopwatchStatus;
    gameStatus: GameStatus;
};

function Stopwatch({ stopwatchStatus, gameStatus }: StopwatchProps) {
    const [timeElapsedMillis, setTimeElapsedMillis] = useState(0);
    const stopwatchIntevalId: any = useRef(undefined);
    const timeStart: any = useRef(undefined);
    const [bestTimeMillis, setBestTimeMillis] = useState(-1);

    useEffect(() => {
        if (stopwatchStatus === StopwatchStatus.ON) {
            startStopwatch();
        } else {
            stopStopwatch();
            if (gameStatus === GameStatus.WIN) {
                updateBestTime();
            }
        }
    });


    function startStopwatch() {
        if (stopwatchIntevalId.current === undefined) {
            stopwatchIntevalId.current = setInterval(updateStopwatch, 1);
            timeStart.current = Date.now();
        }
    }

    function stopStopwatch() {
        clearInterval(stopwatchIntevalId.current);
        stopwatchIntevalId.current = undefined;
    }

    function updateBestTime() {
        if (bestTimeMillis === -1 || timeElapsedMillis < bestTimeMillis) {
            setBestTimeMillis(timeElapsedMillis);
        }
    }

    function updateStopwatch() {
        setTimeElapsedMillis(Date.now() - timeStart.current);
    }

    return (
        <div className="Time">
            <TimeDisplay timeMs={timeElapsedMillis} />
            <span>{" שניות. שיא: "}</span>
            {bestTimeMillis === -1 ?
                "אין" :
                <TimeDisplay timeMs={bestTimeMillis}
                />}
        </div>
    );
}

export default Stopwatch;
