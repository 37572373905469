import React from 'react';
import sfxOn from './resources/sound_on.png';
import sfxOff from './resources/sound_off.png';
import musicOn from './resources/speaker_on.png';
import musicOff from './resources/speaker_mute.png';
import logo from './resources/master-typer-logo.png';
import './App.css';
import TextType, { GameStatus, StopwatchStatus } from './TextType/TextType';
import Stopwatch from './Stopwatch/Stopwatch';
import { useState } from 'react';
import PlayMusic from './PlayMusic/PlayMusic';


function App() {
  const [stopwatchStatus, setStopwatchStatus] = useState(StopwatchStatus.OFF);
  const [gameStatus, setGameStatus] = useState(GameStatus.IDLE);
  const [isSfx, setIsSfx] = useState(true);
  const [isMusic, setIsMusic] = useState(false);

  return (
    <>
      <div className="App">
        <div className="Center-container">
          <div className="Sound-buttons">
            <img src={isSfx ? sfxOn : sfxOff} onClick={() => setIsSfx(!isSfx)} className="soundIcon" alt="Icon to turn sound effects on or off"></img>
            <PlayMusic isMusic={isMusic}></PlayMusic>
            <img src={isMusic ? musicOn : musicOff} onClick={() => setIsMusic(!isMusic)} className="soundIcon" alt="Icon to turn music on or off"></img>
          </div>
          <img src={logo} id="logo" alt="" />
          <Stopwatch stopwatchStatus={stopwatchStatus} gameStatus={gameStatus} />
          <TextType stopwatchStatus={stopwatchStatus} setGameStatus={setGameStatus} setStopwatchStatus={setStopwatchStatus} isSfx={isSfx} />
        </div >
      </div>
    </>
  );
}

export default App;
